<template>
  <div class="main-background">
    <div
      style="
        display: flex;
        width: 100%;
        height: 663px;
        justify-content: center;
        align-content: center;
        position: absolute;
      "
    >
      <img
        class="yellowLine"
        src="@/assets/img/mainBackgroundYellowLine.svg"
        style="
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      />
      <img
        class="redLine"
        src="@/assets/img/mainBackgroundRedLine.svg"
        style="
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
        "
      />
    </div>
    <div class="main">
      <v-main>
        <v-container>
          <v-row no-gutters align="center" style="flex-wrap: nowrap !important">
            <v-col cols="3">
              <img
                v-if="$vuetify.breakpoint.xs"
                src="@/assets/img/logo.svg"
                width="80px"
                height="40px"
                alt="logo"
                @click="$emit('scrollTo', '#main')"
              />
              <img
                v-else
                src="@/assets/img/logo.svg"
                width="140px"
                height="70px"
                alt="logo"
                @click="$emit('scrollTo', '#main')"
              />
            </v-col>
            <v-col cols="6" class="px-0">
              <v-row justify="center" no-gutters>
                <v-item-group
                  active-class="headerActiveItem"
                  v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
                >
                  <v-container>
                    <v-row no-gutters align="center">
                      <v-item
                        v-for="item in headerItem"
                        :key="item.id"
                        v-slot="{ active, toggle }"
                      >
                        <span
                          class="headerItem"
                          @click="toggle, $emit('scrollTo', item.href)"
                          v-if="!active"
                        >
                          <span class="routerLink">
                            {{ item.title | localize }}
                          </span>
                        </span>
                        <span class="headerItem" @click="toggle" v-else>
                          <span class="routerLinkActive">
                            {{ item.title | localize }}
                          </span>
                        </span>
                      </v-item>
                    </v-row>
                  </v-container>
                </v-item-group>
              </v-row>
            </v-col>
            <v-col
              cols="1"
              v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >
              <v-row no-gutters align="center">
                <span
                  class="language"
                  :class="
                    user.locale == 'ua' ? 'activeLanguage' : 'inactiveLanguage'
                  "
                  @click="changeUserLocale('ua')"
                >
                  ua
                </span>
                <span
                  class="language"
                  :class="
                    user.locale == 'en' ? 'activeLanguage' : 'inactiveLanguage'
                  "
                  @click="changeUserLocale('en')"
                >
                  en
                </span>
                <!-- <span
                  class="language"
                  :class="
                    user.locale == 'de' ? 'activeLanguage' : 'inactiveLanguage'
                  "
                  @click="changeUserLocale('de')"
                >
                  de
                </span> -->
              </v-row>
            </v-col>
            <v-col
              cols="2"
              v-if="!$vuetify.breakpoint.sm && !$vuetify.breakpoint.xs"
            >
              <span class="phoneNumber">
                <a
                  style="text-decoration: none;color: white;"
                  href="tel: +380635182737"
                  >+38 (063) 518 27 37</a
                >
              </span>
            </v-col>
            <v-row
              no-gutters
              justify="end"
              v-if="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs"
              style="display: flex; align-items: center; flex-wrap: nowrap"
              @click="$emit('showMobileNavigationDrawer')"
              class="label_menu"
              :style="user.locale == 'de' ? 'padding-right: 50px' : ''"
            >
              <span
                style="
                  font-weight: 400;
                  font-size: 14px;
                  line-height: 19px;
                  color: #ffffff;
                "
                >{{ "label_menu" | localize }}</span
              >
              <v-icon color="white" style="margin-left: 10px">mdi-menu</v-icon>
            </v-row>
          </v-row>
          <div
            style="
              display: flex;
              align-items: center;
              width: 100%;
              padding-top: 135px;
              justify-content: space-between;
            "
            :style="
              $vuetify.breakpoint.sm
                ? 'flex-wrap: wrap;'
                : $vuetify.breakpoint.xs
                ? 'padding-top: 180;flex-wrap: wrap;'
                : ''
            "
          >
            <v-col cols="12" xl="10" lg="9" md="9" sm="12">
              <h1 v-if="$vuetify.breakpoint.xs" class="mainTitle">{{
                "main_title" | localize
              }}</h1>
              <p v-else class="mainTitle">{{ "main_title" | localize }}</p>
              <v-col
                cols="12"
                xl="12"
                lg="7"
                md="7"
                sm="12"
                xs="12"
                class="px-0"
              >
                <p class="mainDescription">
                  {{ "main_description" | localize }}
                </p>
              </v-col>
              <v-row
                no-gutters
                :justify="$vuetify.breakpoint.xs ? 'center' : 'start'"
              >
                <v-btn
                  class="main_btn"
                  @click="$emit('showContactForm')"
                  :style="$vuetify.breakpoint.xs ? 'width: 100%' : ''"
                >
                  <v-col cols="11" style="text-align: center">
                    {{ "main_btn" | localize }}
                  </v-col>
                  <v-col cols="1" class="pt-4">
                    <v-row no-gutters align="center" justify="end">
                      <v-icon size="22"> mdi-arrow-top-right </v-icon>
                    </v-row>
                  </v-col>
                </v-btn>
              </v-row>
            </v-col>
            <v-col
              cols="12"
              xl="2"
              lg="3"
              md="3"
              sm="12"
              style="align-self: flex-end"
            >
              <div class="header__social">
                <div
                  class="header__social-title"
                  style="
                    text-align: center;
                    font-size: 16px;
                    font-weight: 400;
                    color: #fff;
                    line-height: 18px;
                    margin-bottom: 20px;
                  "
                >
                  {{ "main_page_social" | localize }}
                </div>
                <div
                  class="header__social__list"
                  style="
                    display: flex;
                    align-items: flex-end;
                    justify-content: center;
                  "
                >
                  <div
                    class="header__social__list-container"
                    style="margin-left: 28px"
                  >
                    <a
                      href="https://www.linkedin.com/company/bmax-company"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1195_1923)">
                          <path
                            d="M14.0004 0.0938473C11.5691 0.393847 9.21289 1.26885 7.18164 2.61885C4.03164 4.7126 1.73789 7.7751 0.662891 11.3376C0.406641 12.1626 0.156641 13.4188 0.0628906 14.2626C-0.0183594 15.0438 -0.0183594 16.9563 0.0628906 17.7376C0.737891 24.0751 5.21914 29.4876 11.3379 31.3376C12.1629 31.5938 13.4191 31.8438 14.2629 31.9376C15.0441 32.0188 16.9566 32.0188 17.7379 31.9376C21.4379 31.5438 24.8691 29.8626 27.5066 27.1501C29.3129 25.2813 30.5941 23.1188 31.3379 20.6626C31.8004 19.1438 32.0004 17.7376 32.0004 16.0001C32.0004 14.6938 31.9379 13.9751 31.7129 12.8438C30.5816 7.09385 26.2941 2.36885 20.6629 0.662598C19.8379 0.406347 18.5816 0.156347 17.7379 0.0625973C16.9191 -0.0249023 14.8066 -0.00615311 14.0004 0.0938473ZM18.3441 1.68135C24.5816 2.74385 29.3316 7.55635 30.3566 13.8438C30.5129 14.8126 30.5129 17.1876 30.3566 18.1563C29.3191 24.5126 24.5129 29.3188 18.1566 30.3563C17.1879 30.5126 14.8129 30.5126 13.8441 30.3563C7.48789 29.3188 2.68164 24.5126 1.64414 18.1563C1.48789 17.1876 1.48789 14.8126 1.64414 13.8438C2.15664 10.7126 3.56289 7.94385 5.75039 5.7501C8.16289 3.34385 11.2379 1.9001 14.7191 1.5376C15.3879 1.46885 17.6191 1.55635 18.3441 1.68135Z"
                            fill="#6B6B6B"
                          />
                          <path
                            d="M11.3189 10.1939C10.7564 10.3002 10.3002 10.7627 10.1939 11.3314C10.0314 12.2252 10.5939 13.0002 11.4939 13.1064C12.3939 13.2127 13.2127 12.3939 13.1064 11.4939C13.0002 10.5939 12.2189 10.0252 11.3189 10.1939Z"
                            fill="#6B6B6B"
                          />
                          <path
                            d="M10.125 17.8438V21.875H11.625H13.125V17.8438V13.8125H11.625H10.125V17.8438Z"
                            fill="#6B6B6B"
                          />
                          <path
                            d="M14.5 17.8438V21.875H16H17.5V19.5438C17.5 18.1813 17.525 17.125 17.5625 16.9938C17.7188 16.4313 18.15 16.0625 18.6375 16.0625C19.2063 16.0625 19.4563 16.3375 19.5687 17.0813C19.6 17.2938 19.625 18.4625 19.625 19.6688V21.875H21.0938H22.5625V19.325C22.5625 17.3625 22.5437 16.675 22.4688 16.3063C22.2313 15.0563 21.675 14.275 20.7812 13.9438C20.3625 13.7875 19.4062 13.7625 18.9 13.9C18.4375 14.025 17.9562 14.3 17.7125 14.5875L17.5 14.8375V14.325V13.8125H16H14.5V17.8438Z"
                            fill="#6B6B6B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1195_1923">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="header__social__list-container">
                    <a
                      href="https://www.instagram.com/bmax.com.ua/"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1195_1040)">
                          <path
                            d="M14.0004 0.0938473C11.5691 0.393847 9.21289 1.26885 7.18164 2.61885C4.03164 4.7126 1.73789 7.7751 0.662891 11.3376C0.406641 12.1626 0.156641 13.4188 0.0628906 14.2626C-0.0183594 15.0438 -0.0183594 16.9563 0.0628906 17.7376C0.737891 24.0751 5.21914 29.4876 11.3379 31.3376C12.1629 31.5938 13.4191 31.8438 14.2629 31.9376C15.0441 32.0188 16.9566 32.0188 17.7379 31.9376C21.4379 31.5438 24.8691 29.8626 27.5066 27.1501C29.3129 25.2813 30.5941 23.1188 31.3379 20.6626C31.8004 19.1438 32.0004 17.7376 32.0004 16.0001C32.0004 14.6938 31.9379 13.9751 31.7129 12.8438C30.5816 7.09385 26.2941 2.36885 20.6629 0.662598C19.8379 0.406347 18.5816 0.156347 17.7379 0.0625973C16.9191 -0.0249023 14.8066 -0.00615311 14.0004 0.0938473ZM18.3441 1.68135C24.5816 2.74385 29.3316 7.55635 30.3566 13.8438C30.5129 14.8126 30.5129 17.1876 30.3566 18.1563C29.3191 24.5126 24.5129 29.3188 18.1566 30.3563C17.1879 30.5126 14.8129 30.5126 13.8441 30.3563C7.48789 29.3188 2.68164 24.5126 1.64414 18.1563C1.48789 17.1876 1.48789 14.8126 1.64414 13.8438C2.15664 10.7126 3.56289 7.94385 5.75039 5.7501C8.16289 3.34385 11.2379 1.9001 14.7191 1.5376C15.3879 1.46885 17.6191 1.55635 18.3441 1.68135Z"
                            fill="#6B6B6B"
                          />
                          <path
                            d="M11.2188 8.73125C10.125 8.88125 9.21875 9.65625 8.83125 10.7625C8.725 11.075 8.71875 11.3938 8.71875 16C8.71875 20.6063 8.725 20.925 8.83125 21.2375C9.16875 22.2 9.8 22.8312 10.7625 23.1687C11.075 23.275 11.3937 23.2812 16 23.2812C20.6062 23.2812 20.925 23.275 21.2375 23.1687C22.2 22.8312 22.8313 22.2 23.1687 21.2375C23.275 20.925 23.2812 20.6063 23.2812 16C23.2812 11.3938 23.275 11.075 23.1687 10.7625C22.8375 9.81875 22.2375 9.20625 21.275 8.8375C20.9875 8.725 20.6875 8.71875 16.1875 8.7125C13.5563 8.70625 11.325 8.7125 11.2188 8.73125ZM21.0625 12V13.0625H20H18.9375V12V10.9375H20H21.0625V12ZM16.5062 13.1875C17.9938 13.4625 19.0688 14.9563 18.825 16.4125C18.6188 17.65 17.6562 18.6188 16.4188 18.825C14.5813 19.1312 12.8688 17.4187 13.175 15.5812C13.4375 13.9875 14.95 12.9 16.5062 13.1875ZM11.6375 15.9375C11.6063 16.7438 11.7375 17.3312 12.0938 18.05C13.1937 20.2437 15.8563 21.075 18.0813 19.925C18.8438 19.5312 19.5125 18.8438 19.9 18.0625C20.2688 17.3125 20.3938 16.7312 20.3625 15.9187L20.3375 15.3125H21.0813H21.8188L21.8 17.9812C21.7812 20.5375 21.775 20.6688 21.65 20.9375C21.4938 21.2687 21.1625 21.5812 20.8313 21.6938C20.4812 21.8188 11.5187 21.8188 11.1688 21.6938C10.8375 21.5812 10.5063 21.2687 10.35 20.9375C10.225 20.6688 10.2188 20.5375 10.2 17.9812L10.1812 15.3125H10.9188H11.6562L11.6375 15.9375Z"
                            fill="#6B6B6B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1195_1040">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="header__social__list-container">
                    <a href="https://t.me/+380977291012" target="_blank">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1195_1674)">
                          <circle
                            cx="16"
                            cy="16"
                            r="15.25"
                            stroke="#6B6B6B"
                            stroke-width="1.5"
                          />
                          <path
                            d="M14.2498 11.1936C8.0873 13.6123 5.6123 14.6123 5.4873 14.7311C5.15605 15.0498 5.09355 15.6686 5.3623 16.0498C5.44355 16.1561 6.3623 16.7873 7.6373 17.5998L9.78105 18.9686L10.1248 18.9623C10.406 18.9561 10.631 18.8811 11.3435 18.5686C11.8248 18.3498 12.2623 18.1623 12.3123 18.1373C12.3623 18.1186 12.2748 18.2436 12.1123 18.4186C11.9373 18.6123 11.7935 18.8373 11.7498 18.9936C11.706 19.1686 11.6873 19.9436 11.6998 21.3623C11.7185 23.3373 11.7248 23.4811 11.8435 23.6811C12.0123 23.9686 12.2435 24.1248 12.5498 24.1686C13.1185 24.2436 13.181 24.2061 14.4185 22.9811L15.5685 21.8498L17.931 23.0248C20.2748 24.1936 20.2936 24.2061 20.6373 24.1748C21.0185 24.1436 21.3248 23.9686 21.4623 23.6936C21.5686 23.4998 24.1873 9.14985 24.1873 8.78735C24.1873 8.46235 23.9373 8.0436 23.6623 7.91235C23.1748 7.6811 23.4186 7.59985 14.2498 11.1936ZM21.7873 10.6686C21.7623 10.7998 20.1561 19.6498 19.8373 21.4186L19.7998 21.6186L17.7373 20.5873C15.0998 19.2748 15.206 19.2873 14.2685 20.2061L13.7498 20.7186V20.2186V19.7186L15.8685 17.5936C17.7248 15.7311 17.9936 15.4373 18.0311 15.2311C18.1248 14.7311 17.9685 14.3561 17.5623 14.0748C17.1435 13.7936 17.0873 13.8123 13.5498 15.3436C11.7685 16.1186 10.2748 16.7498 10.2373 16.7498C10.1623 16.7498 8.5623 15.7436 8.5623 15.6936C8.5623 15.6561 21.6686 10.5061 21.7686 10.4998C21.7998 10.4998 21.8061 10.5748 21.7873 10.6686Z"
                            fill="#6B6B6B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1195_1674">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                  <div class="header__social__list-container">
                    <a
                      href="https://www.facebook.com/fb.bmax.com.ua"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_1195_1048)">
                          <path
                            d="M14.0004 0.0938473C11.5691 0.393847 9.21289 1.26885 7.18164 2.61885C4.03164 4.7126 1.73789 7.7751 0.662891 11.3376C0.406641 12.1626 0.156641 13.4188 0.0628906 14.2626C-0.0183594 15.0438 -0.0183594 16.9563 0.0628906 17.7376C0.737891 24.0751 5.21914 29.4876 11.3379 31.3376C12.1629 31.5938 13.4191 31.8438 14.2629 31.9376C15.0441 32.0188 16.9566 32.0188 17.7379 31.9376C21.4379 31.5438 24.8691 29.8626 27.5066 27.1501C29.3129 25.2813 30.5941 23.1188 31.3379 20.6626C31.8004 19.1438 32.0004 17.7376 32.0004 16.0001C32.0004 14.6938 31.9379 13.9751 31.7129 12.8438C30.5816 7.09385 26.2941 2.36885 20.6629 0.662598C19.8379 0.406347 18.5816 0.156347 17.7379 0.0625973C16.9191 -0.0249023 14.8066 -0.00615311 14.0004 0.0938473ZM18.3441 1.68135C24.5816 2.74385 29.3316 7.55635 30.3566 13.8438C30.5129 14.8126 30.5129 17.1876 30.3566 18.1563C29.3191 24.5126 24.5129 29.3188 18.1566 30.3563C17.1879 30.5126 14.8129 30.5126 13.8441 30.3563C7.48789 29.3188 2.68164 24.5126 1.64414 18.1563C1.48789 17.1876 1.48789 14.8126 1.64414 13.8438C2.15664 10.7126 3.56289 7.94385 5.75039 5.7501C8.16289 3.34385 11.2379 1.9001 14.7191 1.5376C15.3879 1.46885 17.6191 1.55635 18.3441 1.68135Z"
                            fill="#6B6B6B"
                          />
                          <path
                            d="M16.5688 9.5375C15.7438 9.71875 15.2188 10.0625 14.9125 10.625C14.625 11.15 14.5562 11.5062 14.5188 12.7L14.4813 13.8125H13.775H13.0625V14.9062V16H13.7812H14.5V19.2812V22.5625H16H17.5V19.2812V16H18.4625H19.4188L19.525 15.0437C19.5813 14.525 19.6438 14.0312 19.6625 13.95L19.6875 13.8125H18.5938H17.5V13.0062C17.5 12.5625 17.525 12.125 17.5625 12.0312C17.6625 11.7625 17.9563 11.6875 18.875 11.6875H19.6875V10.5625V9.4375L18.3313 9.44375C17.4125 9.45 16.8375 9.48125 16.5688 9.5375Z"
                            fill="#6B6B6B"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_1195_1048">
                            <rect width="32" height="32" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </a>
                  </div>
                </div>
              </div>
            </v-col>
          </div>
        </v-container>
      </v-main>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  data: () => ({
    headerItem: [
      {
        id: 1,
        title: "header_services",
        href: "#services",
      },
      {
        id: 2,
        title: "header_about_us",
        href: "#abous_us",
      },
      {
        id: 3,
        title: "header_portfolio",
        href: "#portfolio",
      },
      {
        id: 4,
        title: "header_contact",
        href: "#contact",
      },
    ],
    activeItem: 0,
  }),
  methods: {
    ...mapActions(["updateInfo"]),
    changeUserLocale(locale) {
      this.updateInfo({
        locale: locale,
      });
    },
  },
  mounted() {
  },
  computed: {
    ...mapGetters(["user"]),
  },
};
</script>

<style>
@media only screen and (max-width: 609px) {
  .mainTitle {
    font-weight: 500;
    font-size: 48px;
    line-height: 66px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 680px;
    width: 100%;
  }
  .mainDescription {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;
    max-width: 680px;
    width: 100%;
  }
  .yellowLine {
    animation: spinYellowLine 6s linear infinite;
    margin-top: 100px;
    width: 200px;
    height: 200px;
    overflow: hidden;
    transition-duration: 0.8s;
    transition-property: transform;
  }
  .redLine {
    animation: spinRedLine 6s linear infinite;
    position: absolute;
    width: 75px;
    height: 75px;
    object-fit: cover;
    margin-top: 150px;
    overflow: hidden;
    transition-duration: 0.8s;
    transition-property: transform;
  }
  .textStyle {
    font-weight: 700;
    font-size: 20px;
    line-height: 160%;
    color: #31000f;
  }
}
@media only screen and (min-width: 610px) {
  .mainTitle {
    font-weight: 500;
    font-size: 64px;
    line-height: 88px;
    text-transform: uppercase;
    color: #ffffff;
    max-width: 680px;
    width: 100%;
  }
  .mainDescription {
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #ffffff;
    max-width: 680px;
    width: 100%;
  }
  .yellowLine {
    animation: spinYellowLine 6s linear infinite;
    margin-top: 50px;
    overflow: hidden;
    transition-duration: 0.8s;
    transition-property: transform;
  }
  .redLine {
    animation: spinRedLine 6s linear infinite;
    position: absolute;
    width: 370px;
    height: 370px;
    object-fit: cover;
    margin-top: 160px;
    overflow: hidden;
    transition-duration: 0.8s;
    transition-property: transform;
  }
  .textStyle {
    font-weight: 700;
    font-size: 48px;
    line-height: 160%;
    color: #31000f;
  }
}
@keyframes spinYellowLine {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes spinRedLine {
  100% {
    -webkit-transform: rotate(-360deg);
    transform: rotate(-360deg);
  }
}
.headerItem {
  color: white;
  margin-right: 32px;
  cursor: pointer;
}

.language {
  /* font-family: "MacPaw Fixel Display"; */
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  margin-right: 10px;
  cursor: pointer;
}

.activeLanguage {
  color: #ffffff;
}
.inactiveLanguage {
  color: #727272;
}
.routerLink {
  text-decoration: none;
  color: white !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 17px;
  transition: color 0.3s;
}
.routerLink:hover {
  color: #fea31e !important;
}
.routerLinkActive {
  color: #fea31e !important;
  text-decoration: none;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
}
.whiteText {
  color: white;
}
.main_btn {
  width: 380px;
  min-height: 50px;
}
.header__social__list-container {
  cursor: pointer;
  margin-right: 28px;
}
.header__social__list-container svg path {
  transition: all 0.3s;
}
.header__social__list-container:hover svg path {
  fill: #fea31e;
}
.header__social__list-container svg circle {
  transition: all 0.3s;
}
.header__social__list-container:hover svg circle {
  stroke: #fea31e;
}
.phoneNumber a{
  transition: all .3s;
}
.phoneNumber:hover a{
  color: #fea31e !important;
}
.label_menu span, .label_menu i{
  cursor: pointer;
}
</style>
