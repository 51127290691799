var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-col',[_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-text-field',{class:_vm.nameError.length
            ? 'error_text-field'
            : !_vm.$v.userV.name.$invalid && _vm.userV.name
            ? 'success_text-field'
            : 'text_field',staticStyle:{"border-radius":"20px"},style:(_vm.$vuetify.breakpoint.xs ? '' : 'padding-right: 10px'),attrs:{"dense":"","outlined":"","label":_vm.user.locale == 'ua' ? _vm.examples.name_ua : _vm.user.locale == 'en' ? _vm.examples.name_en : _vm.examples.name_de,"color":"#0D0D0D","error-messages":_vm.nameError},model:{value:(_vm.userV.name),callback:function ($$v) {_vm.$set(_vm.userV, "name", $$v)},expression:"userV.name"}}),_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('+##################'),expression:"'+##################'"}],class:_vm.phoneNumberError.length
            ? 'error_text-field'
            : !_vm.$v.userV.phone_number.$invalid && _vm.userV.phone_number
            ? 'success_text-field'
            : 'text_field',staticStyle:{"border-radius":"20px"},style:(_vm.$vuetify.breakpoint.xs ? '' : 'padding-left: 10px'),attrs:{"dense":"","outlined":"","label":_vm.user.locale == 'ua' ? _vm.examples.phone_ua : _vm.user.locale == 'en' ? _vm.examples.phone_en : _vm.examples.phone_de,"color":"#0D0D0D","error-messages":_vm.phoneNumberError},model:{value:(_vm.userV.phone_number),callback:function ($$v) {_vm.$set(_vm.userV, "phone_number", $$v)},expression:"userV.phone_number"}})],1),_c('v-row',{attrs:{"no-gutters":"","align":"center"}},[_c('v-text-field',{class:_vm.emailError.length
            ? 'error_text-field'
            : !_vm.$v.userV.email.$invalid && _vm.userV.email
            ? 'success_text-field'
            : 'text_field',staticStyle:{"border-radius":"20px"},style:(_vm.$vuetify.breakpoint.xs ? '' : 'padding-right: 10px'),attrs:{"dense":"","outlined":"","label":_vm.user.locale === 'ua' ? _vm.examples.mail_ua : _vm.user.locale === 'en' ? _vm.examples.mail_en : _vm.examples.mail_de,"color":"#0D0D0D","error-messages":_vm.emailError},model:{value:(_vm.userV.email),callback:function ($$v) {_vm.$set(_vm.userV, "email", $$v)},expression:"userV.email"}}),_c('v-text-field',{class:_vm.companyNameError.length
            ? 'error_text-field'
            : !_vm.$v.userV.company_name.$invalid && _vm.userV.company_name
            ? 'success_text-field'
            : 'text_field',staticStyle:{"border-radius":"20px"},style:(_vm.$vuetify.breakpoint.xs ? '' : 'padding-left: 10px'),attrs:{"dense":"","outlined":"","label":_vm.user.locale === 'ua' ? _vm.examples.company_ua : _vm.user.locale === 'en' ? _vm.examples.company_en : _vm.examples.company_de,"color":"#0D0D0D","error-messages":_vm.companyNameError},model:{value:(_vm.userV.company_name),callback:function ($$v) {_vm.$set(_vm.userV, "company_name", $$v)},expression:"userV.company_name"}})],1),_c('v-textarea',{staticStyle:{"border-radius":"20px"},attrs:{"dense":"","outlined":"","label":_vm.user.locale === 'ua' ? _vm.examples.text_ua : _vm.user.locale === 'en' ? _vm.examples.text_en : _vm.examples.text_de,"color":"#0D0D0D","height":"80px"},model:{value:(_vm.userV.project_description),callback:function ($$v) {_vm.$set(_vm.userV, "project_description", $$v)},expression:"userV.project_description"}}),_c('v-row',{attrs:{"no-gutters":"","justify":_vm.$vuetify.breakpoint.xs ? 'center' : 'end'}},[_c('v-btn',{staticClass:"main_btn",staticStyle:{"margin-top":"10px"},attrs:{"width":_vm.$vuetify.breakpoint.xs ? '100%' : '220px',"height":"45px"},on:{"click":_vm.sendMessage}},[_c('v-row',{attrs:{"justify":"center","no-gutters":"","align":"center"}},[_vm._v(" "+_vm._s(_vm._f("localize")("btn_send"))+" "),_c('v-icon',{staticStyle:{"margin-left":"10px"},attrs:{"small":""}},[_vm._v(" mdi-arrow-top-right ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }