import store from '../store'
import en from '@/locales/en.json'
import ua from '@/locales/ua.json'
// import de from '@/locales/de.json'

const locales = {
    'en': en,
    'ua': ua,
		// 'de': de
}

export default function localizeFilter(key) {
    let locale = "ua"
    locale = store.getters.user.locale || "ua"
    return locales[locale][key]
}