<template>
  <v-main>
    <v-container>
      <v-col class="px-0">
        <p class="componentTitle">{{ "why_they_choose_us" | localize }}</p>
        <div class="blurBackground"></div>
        <div class="aboutUsText">
          <div
            class="aboutUsPar"
            v-html="$options.filters.localize('about_us_before_ul_text')"
          ></div>
          <div class="aboutUsPar" v-html="$options.filters.localize('about_us_ul')"></div>
          <div class="aboutUsPar"
            v-html="$options.filters.localize('about_us_after_ul_text')"
          ></div>
        </div>
      </v-col>
    </v-container>
  </v-main>
</template>

<script>
export default {};
</script>

<style>
.aboutUsText {
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  margin-bottom: 100px;
  padding-right: 30px;
  padding-bottom: 10px;
}
.aboutUsPar li{
	color: #fff;
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.aboutUsPar .componentSubtitle {
  color: #fff;
  text-align: justify;
  font-size: 20px;
  font-weight: 400;
  line-height: 24px;
}
.blurBackground {
  width: 100%;
  margin-top: 190px;
  height: 20px;
  background: black;
  position: absolute;
  z-index: 10;
  filter: blur(10px);
}
.aboutUsText::-webkit-scrollbar {
  width: 2px;
}
.aboutUsText::-webkit-scrollbar-track {
  background: #c5c5c5;
}
.aboutUsText::-webkit-scrollbar-thumb {
  background: #fea31e;
}
.aboutUsText::-webkit-scrollbar-thumb:hover {
  background: #8a5300;
}
@media(max-width: 609px)
{
	.aboutUsText{
		height: 400px;
	}
	.blurBackground{
		margin-top: 390px;
	}
	.aboutUsPar li{
		font-size: 16px;
		line-height: 20px;
	}
	.aboutUsPar .componentSubtitle{
		font-size: 16px;
		line-height: 20px;
	}
}
</style>
