<template>
  <v-main>
    <v-container>
      <v-row no-gutters align="start" justify="center">
        <v-col cols="12" xl="4" lg="4" md="4" sm="12">
          <p class="componentTitle" style="color: black">
            {{ "example_of_our_work" | localize }}
          </p>
          <swiper
            class="swiper d-block d-md-none"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperMobileOption"
          >
            <swiper-slide v-for="item in examples" :key="item.id">
              <v-row no-gutters align="center" justify="center">
                <examples-mobile-card :item="item" />
              </v-row>
            </swiper-slide>
          </swiper>
          <v-row
            no-gutters
            align="center"
            :justify="$vuetify.breakpoint.sm || $vuetify.breakpoint.xs  ? 'center' : 'start'"
            :style="$vuetify.breakpoint.sm  || $vuetify.breakpoint.xs  ? 'margin-top: 30px' : 'margin-top: 50px'"
          >
            <div class="swiper-button-prev" style="position: static"></div>
            <div
              class="swiper-button-next"
              style="position: static"
              :style="
                $vuetify.breakpoint.xs
                  ? 'margin-left: 80px'
                  : 'margin-left: 150px'
              "
            ></div>
          </v-row>
        </v-col>
        <v-col cols="8">
          <swiper
            class="swiper d-none d-lg-block"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperOption"
          >
            <swiper-slide v-for="(item, index) in examples" :key="item.id">
              <v-row no-gutters align="center">
                <examples-card :item="item" />
                <div
                  v-if="index !== 0"
                  style="
                    border: 1px solid rgba(208, 208, 208, 0.4);
                    z-index: 80;
                    height: 200px;
                    position: absolute;
                  "
                />
              </v-row>
            </swiper-slide>
          </swiper>
          <swiper
            class="swiper d-none d-md-block d-lg-none"
            :style="{
              '--swiper-navigation-color': '#000000',
            }"
            :options="swiperMediumOption"
          >
            <swiper-slide v-for="(item, index) in examples" :key="item.id">
              <v-row no-gutters align="center">
                <examples-card :item="item" />
                <div
                  v-if="index !== 0"
                  style="
                    border: 1px solid rgba(208, 208, 208, 0.4);
                    z-index: 80;
                    height: 200px;
                    position: absolute;
                  "
                />
              </v-row>
            </swiper-slide>
          </swiper>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import ExamplesCard from "./examplesCard.vue";
import ExamplesMobileCard from "./examplesMobileCard.vue";
export default {
  name: "swiper-example-loop-group",
  title: "Loop mode with multiple slides per group",
  components: {
    Swiper,
    SwiperSlide,
    ExamplesCard,
    ExamplesMobileCard,
  },
  data: () => ({
    examples: [
      {
        id: 1,
        title_ua: "Онлайн склад",
        title_en: "Online warehouse 'NewSend'",
				title_de: "Online-Lager",
        text_ua:
          "NewSend - Ваш онлайн склад! Це сучасний онлайн сервіс для відправки товарів вашим клієнтам",
        text_en:
          "NewSend - Your online warehouse! This is a modern online service for sending goods to your customers",
				text_de: "NewSend – Ihr Online-Lager! Es ist modern Online-Dienst zum Versenden von Waren Ihre Kunden",
        img: "newSend.png",
        href: 'https://my.newsend.ua/uk/landing',
      },
      {
        id: 2,
        title_ua: "Компанія пасажироперевізник MBus",
        title_en: "MBus passenger carrier company",
				title_de: "MBus-Personentransportunternehmen",
        text_ua:
          "Онлайн система, яка служить не тільки візитною карткою компанії MBus, а і дозволяє купити квиток на потрібну дату поїздки,  відстежити автобус у онлайн режимі та інші зручні сервіси",
        text_en:
          "An online system that serves not only business card of the MBus company, and allows buy a ticket for the desired travel date, track the bus online and others convenient services",
				text_de: "Das Online-System dient nicht nur als Visitenkarte des MBus-Unternehmens, sondern ermöglicht Ihnen auch den Kauf einer Fahrkarte für das gewünschte Reisedatum, die Online-Tracking des Busses und andere praktische Dienste",
        img: "MBus.png",
        href: 'http://mbus.if.ua/',
      },
      {
        id: 3,
        title_ua: "Сайт навчального закладу",
        title_en: "Website of the educational institution",
				title_de: "Website der Bildungseinrichtung",
        text_ua:
          "Сайт виконує роль не тільки візетівки закладу, а надає можливість відстежання розкладу навчального процесу, зручну адмін зону з різними правами доступу та інших сервісів необхідних для функціонування закладу",
        text_en:
          "The site performs the role of not only a business card of the institution, and provides an opportunity to track the educational schedule process, a convenient admin area with different access rights and other services necessary for the functioning of the institution",
				text_de: "Die Website fungiert nicht nur als Visitenkarte der Einrichtung, sondern bietet auch die Möglichkeit, den Zeitplan des Bildungsprozesses zu verfolgen, einen komfortablen Verwaltungsbereich mit verschiedenen Zugriffsrechten und andere für das Funktionieren der Einrichtung erforderliche Dienste",
        img: "kpk.png",
        href: 'https://kpk-lp.com.ua/',
      },
      {
        id: 4,
        title_ua: "Велика бізнес - система",
        title_en: "A large business system",
				title_de: "Ein großes Geschäftssystem",
        text_ua: "Hoozie - велика система для бізнесів у Гвадалахарі",
        text_en: "Hoozie is a great system for businesses in Guadalajara",
				text_de: "Hoozie ist ein großartiges System für Unternehmen in Guadalajara",
        img: "hoozie.png",
        href: 'https://www.hoozie.io/',
      },
      {
        id: 5,
        title_ua: "Покупка квитків на атракціони",
        title_en: "Buying tickets for attractions",
				title_de: "Kauf von Eintrittskarten für Attraktionen",
        text_ua: "Веб - сайт на якому можна придбати квитки на атракціони",
        text_en: "Website where you can buy tickets for attractions",
				text_de: "Eine Website, auf der Sie Tickets für Attraktionen kaufen können",
        img: "citypass.png",
        href: 'https://citypass.hoozaround.com/',
      },  
      {
        id: 6,
        title_ua: "Сайт для компанії 'GLOBAL PRO EXPORT'",
        title_en: "The website of the company 'GLOBAL PRO EXPORT'",
				title_de: "Website für das Unternehmen „GLOBAL PRO EXPORT“",
        text_ua:
          "Онлайн система, яка служить не тільки візитною карткою для компанії, а і дозволяє ознайомитися з каталогом продукції, зробити онлайн замовлення та вибрати зручний спосіб доставки.",
        text_en:
          "An online system that serves not only as a visiting system card for the company, and allows you to familiarize yourself with a catalog of products, make an online order and choose a convenient delivery method.",
				text_de: "Das Online-System dient nicht nur als Visitenkarte des Unternehmens, sondern ermöglicht Ihnen auch, sich mit dem Produktkatalog vertraut zu machen, eine Online-Bestellung aufzugeben und eine bequeme Lieferart zu wählen.",
        img: "tablet.svg",
        href: 'https://proexport.prod.bmax.com.ua/',
      },
      {
        id: 7,
        title_ua: "Сайт для компанії - NRM SE",
        title_en: "Website for the company - NRM SE",
				title_de: "Unternehmenswebsite - NRM SE",
        text_ua:
          "Розробили сайт для NRM SE, компанії, яка відкриває двері для глобального бізнесу в Україні та забезпечує доступ до європейських ринків.",
        text_en:
          "We developed a website for NRM SE, a company that opens doors for global business in Ukraine and provides access to European markets.",
				text_de: "Wir haben eine Website für NRM SE entwickelt, ein Unternehmen, das Türen für globale Geschäfte in der Ukraine öffnet und Zugang zu europäischen Märkten bietet.",
        img: "rostermedia.png",
        href: 'https://rostermedia.cz/',
      },
      {
        id: 8,
        title_ua: "Сайт для замовлення модульних будинків",
        title_en: "Site for ordering modular houses",
				title_de: "Website zur Bestellung von Modulhäusern",
        text_ua:
          "Сайт для RIETLI IMMOBILIEN AG, компанії, яка спеціалізується на створенні готових до заселення модульних будинків.",
        text_en:
          "Site for RIETLI IMMOBILIEN AG, a company specializing in the creation of ready-to-move-in modular homes.",
				text_de: "Standort der RIETLI IMMOBILIEN AG, einem auf die Erstellung bezugsfertiger Modulhäuser spezialisierten Unternehmen.",
        img: "rietli.png",
        href: 'https://rietli-immobilien.ch/',
      },
      {
        id: 9,
        title_ua: "Компанія пасажироперевізник travelbus",
        title_en: "Travelbus passenger transport company",
				title_de: "Reisebus-Personenbeförderungsunternehmen",
        text_ua:
          "Наша онлайн система - не лише візитка travelbus, але й зручний спосіб придбати квиток на вибрану дату поїздки, відстежити автобус у режимі реального часу та скористатися іншими зручними сервісами.",
        text_en:
          "Our online system is not only a travelbus business card, but also a convenient way to buy a ticket for the selected travel date, track the bus in real time and use other convenient services.",
				text_de: "Unser Online-System ist nicht nur eine Reisebus-Visitenkarte, sondern auch eine bequeme Möglichkeit, ein Ticket für das ausgewählte Reisedatum zu kaufen, den Bus in Echtzeit zu verfolgen und andere praktische Dienste zu nutzen.",
        img: "travelBus.png",
        href: 'https://travelbus.biz.ua/',
      },
      {
        id: 10,
        title_ua: "Компанія пасажироперевізник Amexpress",
        title_en: "Amexpress passenger carrier company",
				title_de: "Amexpress-Personentransportunternehmen",
        text_ua:
          "Наша онлайн система - це не лише візитна картка компанії amexpress, а й вхід до зручних сервісів, включаючи замовлення квитків на обрану дату та відстеження руху автобусів онлайн.",
        text_en:
          "Our online system is not only a business card of the amexpress company, but also an entrance to convenient services, including ordering tickets for the selected date and tracking bus traffic online.",
				text_de: "Unser Online-System ist nicht nur eine Visitenkarte des Unternehmens amexpress, sondern auch ein Zugang zu praktischen Diensten, darunter die Bestellung von Tickets für das ausgewählte Datum und die Online-Verfolgung des Busverkehrs.",
        img: "amexpress.png",
        href: 'https://amexpress.if.ua/',
      },
      {
        id: 11,
        title_ua: "Сайт для компанії - BlendJet",
        title_en: "Website for the company - BlendJet",
				title_de: "Website des Unternehmens – BlendJet",
        text_ua:
          "Розробили сайт для компанії BlendJet, де можна не тільки зробити замовлення, а і переглянути рецепти  для блендерів.",
        text_en:
          "We developed a website for the BlendJet company, where you can not only place an order, but also view recipes for blenders.",
				text_de: "Für die Firma BlendJet haben wir eine Website entwickelt, auf der Sie nicht nur eine Bestellung aufgeben, sondern auch Rezepte für Mixer ansehen können.",
        img: "blendjet.png",
        href: 'https://jet.front-dev.bmax.com.ua/',
      },
    ],
    swiperOption: {
      slidesPerView: 1.5,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: false,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperMobileOption: {
      slidesPerView: 1,
      spaceBetween: 20,
      slidesPerGroup: 1,
      loop: false,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
    swiperMediumOption: {
      slidesPerView: 1.5,
      spaceBetween: 0,
      slidesPerGroup: 1,
      loop: false,
      loopFillGroupWithBlank: false,
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    },
  }),
};
</script>

<style>
</style>